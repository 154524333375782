export function updateBlocks(editor) {
  editor.BlockManager.remove("quote");
  const blocks = editor.BlockManager.getAll();
  console.warn("blocks", blocks);
  const filtered = blocks?.filter((block) => block?.category == "Basic");

  editor.BlockManager.render(filtered);

  const countdownBlock = editor.BlockManager.get("countdown"); // Use the correct block name
  const accordionsBlock = editor.BlockManager.get("accordions"); // Use the correct block name
  const selectBlock = editor.BlockManager.get("select"); // Use the correct block name

  if (accordionsBlock) {
    accordionsBlock.set({
      label: "Accordion",
      attributes: {
        class: "", // Remove the FontAwesome class
      },
      category: "Extra",

      media: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve"><g><polygon points="79.7,43.5 74,37.9 50.2,62.2 25.9,38.4 20.3,44.2 50.4,73.5  "/></g><text x="0" y="115" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text><text x="0" y="120" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text></svg>`,
    });
  }

  // Update the label
  if (countdownBlock) {
    countdownBlock.set({
      label: "Countdown Timer",
    });
  }

  if (selectBlock) {
    selectBlock.set({
      label: `
                <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
                    <polygon class="gjs-block-svg-path" transform="translate(18.500000, 12.000000) scale(1, -1) translate(-18.500000, -12.000000) " points="18.5 11 20 13 17 13"></polygon>
                    <rect class="gjs-block-svg-path" x="4" y="11.5" width="11" height="1"></rect>
                </svg>
                <div class="gjs-block-label">Dropdown</div>
            `,
    });
  }

  // Reorder categories
  const basicCategory = editor.BlockManager.getCategories().filter(
    (cat) => cat.id == "Basic"
  )[0];
  const extraCategory = editor.BlockManager.getCategories().filter(
    (cat) => cat.id == "Extra"
  )[0];
  const formCategory = editor.BlockManager.getCategories().filter(
    (cat) => cat.id == "Forms"
  )[0];

  if (basicCategory) {
    basicCategory.set("order", 1);
  }

  if (formCategory) {
    formCategory.set("order", 2);
  }
  if (extraCategory) {
    extraCategory.set("order", 3);
  }
}
