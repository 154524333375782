import type grapesjs from 'grapesjs';
import { PluginOptions } from '.';
import { typeCustomCode } from './utils.ts';

export default (editor: grapesjs.Editor, { blockCustomCode }: PluginOptions = {}) => {
  const { Blocks } = editor;

  blockCustomCode && Blocks.add(typeCustomCode, {
    label: 'Sendpad Form',
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlns:v="https://vecta.io/nano"><g fill="#b2b9c3"><use xlink:href="#B"/><use xlink:href="#B" x="-4.99" y="4.99"/><path d="M5.626 5.627l4.989-4.99a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0L5.626 6.899a.9.9 0 0 1 0-1.273zm5.626-3.08L7.535 6.263l3.717 3.717 3.717-3.717zm.001 19.585H1.274a.9.9 0 0 1-.636-1.536l4.99-4.99a.9.9 0 0 1 1.273 0l4.99 4.99a.9.9 0 0 1-.636 1.536zm-7.806-1.8h5.634l-2.817-2.817z"/><path d="M1.273.373h9.979a.9.9 0 0 1 .636 1.536l-4.99 4.99a.9.9 0 0 1-1.273 0l-4.99-4.99A.9.9 0 0 1 1.271.373zm7.806 1.8H3.445L6.262 4.99z"/><path d="M.637 10.616l4.989-4.989a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0L.637 11.888a.9.9 0 0 1 0-1.273zm5.626-3.08l-3.717 3.717 3.717 3.717 3.717-3.717z"/></g><defs ><path id="B" d="M10.616 10.616l4.989-4.989a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0l-4.989-4.989a.9.9 0 0 1 0-1.273zm5.626-3.08l-3.717 3.717 3.717 3.717 3.717-3.717z"/></defs></svg>
    `,
    category: 'Forms',
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...blockCustomCode
  }); 
}
