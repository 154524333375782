import { property } from "lodash";

const backgroundImageDefault = (editor) => {
  const styleManager = editor.StyleManager;
  const backgroundSector = styleManager
    .getSectors()
    .find((sector) => sector.get("name") === "Decorations");
  //   const backgroundSector = styleManager.getSectors().find((sector) => {
  //     alert(sector.get("name"));
  //   });

  if (backgroundSector) {
    const bgImageProperty = backgroundSector
      .get("properties")
      .find((prop) => prop.get("property") === "background");
    const propRepeat = bgImageProperty
      .get("properties")
      .find((prop) => prop.get("property") === "background-repeat");
    if (propRepeat) propRepeat.set({ default: "no-repeat" });

    const propSize = bgImageProperty
      .get("properties")
      .find((prop) => prop.get("property") === "background-size");
    if (propSize) propSize.set({ default: "cover" });

    const propPosition = bgImageProperty
      .get("properties")
      .find((prop) => prop.get("property") === "background-position");
    if (propPosition) propPosition.set({ default: "center center" });

    editor.StyleManager.render();

    return null;
  } else {
    alert("Background sector not found.");
    return null;
  }
};

export default backgroundImageDefault;
